import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/styles';
import { amplitudeBaseUrl } from './InstructorVerificationLevelCard';

const useStyles = makeStyles((theme) => createStyles({
  paper: {
    width: 320,
    margin: `${theme.spacing(1)}px`,
    padding: theme.spacing(2),
  },
  largeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const UserLogs = ({ user }) => {
  const classes = useStyles();

  const logtailBaseUrl = 'https://logs.betterstack.com/team/150105/tail?v=68291&rf=now-24h&q=';
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h6">
            User Logs
          </Typography>
          <Typography variant="body2">
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    `${logtailBaseUrl}(message.message.status%3D400%20OR%20message.message.status>421)%20AND%20message.user_id%3A%22${user.id}%22`
                  }
                >
                  API Error Logs
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    `${logtailBaseUrl}message.user_id%3A%22${user.id}%22`
                  }
                >
                  All User Logs
                </a>
              </li>

              <li>
                <a
                  href={`${amplitudeBaseUrl}/search/user_id%3D${user.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Amplitude Data
                </a>
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserLogs;
