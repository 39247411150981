import * as React from 'react';
import { useCallback } from 'react';
import {
  DateInput,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import api from '../api';

const InstructorEditToolbar = (props) => (
  <Toolbar {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const InstructorEdit = (props) => {
  const notify = useNotify();
  const history = useHistory();

  const save = useCallback((record) => {
    api.put(`instructors/${record.id}`, {
      instructor: {
        trial_end_at: record.trial_end_at,
      },
    })
      .then(() => {
        notify('Updated Instructor');
        history.goBack();
      })
      .catch((e) => {
        notify(`Error updating Instructor: ${e}`, 'error');
      });
  });

  return (
    <Edit title="Edit Instructor" {...props}>
      <SimpleForm toolbar={<InstructorEditToolbar />} save={save}>
        <TextInput disabled label="Id" source="id" />
        <DateInput source="trial_end_at" />
      </SimpleForm>
    </Edit>
  );
};

export default InstructorEdit;
