import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/styles';
import {
  get,
  isNil,
} from 'lodash';
import {
  Button,
  Confirm,
  DateField,
  Error,
  useNotify,
} from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import api from '../../api';

export const useStyles = makeStyles((theme) => createStyles({
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  actionSpacer: {
    padding: theme.spacing(1),
  },
}));

const InstructorSubscriptionCard = ({ instructor, onRefresh }) => {
  const classes = useStyles();
  const notify = useNotify();
  const history = useHistory();

  const { subscription, in_free_trial: inFreeTrial } = instructor;
  const plan = get(subscription, 'plan');
  const btBaseUrl = `https://${process.env.REACT_APP_BT_SUBDOMAIN || 'sandbox'}.braintreegateway.com/merchants/${process.env.REACT_APP_BT_MERCHANT_ID || 'g788jwfb4hfjdrc8'}/subscriptions`;

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [error, setError] = useState();

  const cancelSubscription = () => {
    setCancelling(true);
    api.put(`instructors/${instructor.id}/subscription_cancel`)
      .then(() => {
        onRefresh();
        setCancelling(false);
        notify('Subscription Cancelled');
      })
      .catch((e) => {
        notify('Error cancelling subscription', 'error');
        setError(e);
        setCancelling(false);
      })
      .finally(() => {
        setShowCancelConfirmation(false);
      });
  };

  if (error) return <Error error={error} />;

  const editInstructor = () => {
    history.push(`/instructors/${instructor.id}/edit`);
  };

  let isExpiring = false;
  let billingCycleText = '';
  let cancellable = false;
  if (plan) {
    isExpiring = !isNil(plan.number_of_billing_cycles);
    billingCycleText = `${plan.current_billing_cycle} of ${isExpiring ? plan.number_of_billing_cycles : 'Unlimited'}`;
    cancellable = !isExpiring && plan.status !== 'expired' && plan.status !== 'canceled';
  }
  const discountCode = get(instructor, 'discount.code');
  return (
    <Card className={classes.card} variant="outlined">
      <div className={classes.details}>
        <CardContent className={classes.content}>
          {!plan && (
            <Grid container spacing={2} style={{ paddingTop: 16 }}>
              <Grid item>
                <Chip label="Not Subscribed" color="secondary" />
              </Grid>
              <Grid item>
                {discountCode && <Chip label={`Discount: ${discountCode}`} color="primary" variant="outlined" />}
              </Grid>
            </Grid>
          )}
          {plan && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>ID:</b>
                  {' '}
                  <a
                    href={`${btBaseUrl}/${plan.subscription_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {plan.subscription_id}
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Chip label={plan.name} color="secondary" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Next Bill:</b>
                  {` ${get(plan, 'next_bill_amount.formatted', '$?')} on `}
                  <DateField
                    record={plan}
                    source="next_bill_date"
                  />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Billing Cycle:</b>
                  {` ${billingCycleText}`}
                </Typography>
              </Grid>
              <Grid container spacing={2} style={{ paddingTop: 16 }}>
                <Grid item>
                  <Chip label={plan.status} color="primary" />
                </Grid>
                <Grid item>
                  {isExpiring && plan.status !== 'expired' && <Chip label="Expiring" color="primary" />}
                </Grid>
                <Grid item>
                  {discountCode && <Chip label={`Discount: ${discountCode}`} color="primary" variant="outlined" />}
                </Grid>
              </Grid>
            </Grid>
          )}
          {inFreeTrial && (
            <Grid container spacing={2} style={{ paddingTop: 16 }}>
              <Grid item>
                <Chip
                  label={`Free Trial${instructor.trial_end_at ? ` Until ${DateTime.fromISO(instructor.trial_end_at).toISODate()}` : ''}`}
                  color="secondary"
                />
              </Grid>
            </Grid>
          )}
        </CardContent>
        <CardActions
          classes={{ spacing: classes.actionSpacer }}
        >
          {cancellable && (
            <Button
              variant="contained"
              color="secondary"
              label="Cancel Subscription"
              onClick={() => setShowCancelConfirmation(true)}
            >
              <CancelIcon />
            </Button>
          )}
          {!cancellable && (
            <Button
              variant="contained"
              color="primary"
              label="Edit Free Trial"
              onClick={editInstructor}
            >
              <EditIcon />
            </Button>
          )}
        </CardActions>
      </div>

      <Confirm
        loading={cancelling}
        isOpen={showCancelConfirmation}
        title="Cancel Subscription?"
        content={cancelling ? 'Cancelling...' : 'Are you sure you want to cancel this subscription?'}
        confirm="Yes"
        confirmColor="primary"
        cancel="No"
        onConfirm={cancelSubscription}
        onClose={() => setShowCancelConfirmation(false)}
      />
    </Card>
  );
};

export default InstructorSubscriptionCard;
