import axios from 'axios';
import { apiServer } from './authProvider';

const instance = axios.create({
  baseURL: apiServer,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

instance.interceptors.request.use(
  (request) => {
    request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return Promise.resolve(request);
  },
);

export default instance;
