/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from '@material-ui/core';
import { Button } from 'react-admin';
import LaunchIcon from '@material-ui/icons/Launch';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import ClipboardListField from '../ClipboardListField';

export const useStyles = makeStyles((theme) => createStyles({
  media: {
    width: 160,
  },
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  actionSpacer: {
    padding: theme.spacing(2),
  },
}));

const SiteCard = ({ site }) => {
  const classes = useStyles();
  const history = useHistory();

  const visitSite = () => (
    window.open(
      `http://${site.domain_name}.${process.env.REACT_APP_BASE_HOSTNAME || 'lvh.me:5000'}`,
      '_blank',
    )
  );

  const editSite = () => {
    history.push(`/sites/${site.id}/edit`);
  };

  const copySiteRows = (value) => [
    value,
    `Site.find_by(id: "${value}")`,
  ];

  return (
    <Card className={classes.card} variant="outlined">
      <CardMedia
        image={site.site_logo.url}
        className={classes.media}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="body1">
            <b>ID:</b>
          </Typography>
          <ClipboardListField source="id" createRows={copySiteRows} record={site} />
          <Typography variant="body1">
            <b>Domain:</b>
            {' '}
            {site.domain_name}
          </Typography>
          <div>&nbsp;</div>
          <Typography component="span" variant="body1">
            <b>Custom Domain:</b>
            {site.custom_domain && (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
              >
                {site.custom_domain}
                <a href={`/#/domains?domain=${site.custom_domain}`} title="Lookup"><SearchIcon /></a>
              </div>
            )}
            {' '}
            {site.custom_domain_disabled && <Chip label="Disabled" variant="outlined" />}
            {site.provisioning_domain && <Chip label="Provisioning" variant="outlined" />}
          </Typography>
          <div>&nbsp;</div>
          {!site.publish && <Chip label="Not Published" color="secondary" />}
        </CardContent>

        <CardActions
          classes={{ spacing: classes.actionSpacer }}
        >
          <Button
            variant="contained"
            color="primary"
            label="Visit"
            onClick={visitSite}
          >
            <LaunchIcon />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            label="Edit"
            onClick={editSite}
          >
            <EditIcon />
          </Button>
        </CardActions>
      </div>
    </Card>
  );
};

export default SiteCard;
