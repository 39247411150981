import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from '@material-ui/core';
import { Button } from 'react-admin';
import LaunchIcon from '@material-ui/icons/Launch';
import * as React from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/styles';
import ClipboardListField from '../ClipboardListField';

export const useStyles = makeStyles((theme) => createStyles({
  media: {
    width: 160,
  },
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  actionSpacer: {
    padding: theme.spacing(2),
  },
}));

const MerchantAccountCard = ({ account }) => {
  const classes = useStyles();

  const visitAccount = () => (
    window.open(`https://dashboard.stripe.com/${account.processor_account_id}`, '_blank')
  );

  const visitAccountDetails = () => (
    window.open(`https://dashboard.stripe.com/connect/accounts/${account.processor_account_id}`, '_blank')
  );

  const copyAccountRows = (value) => [
    value,
    `MerchantAccount.find_by(id: "${value}")`,
  ];

  const copyProcessorRows = (value) => [
    value,
  ];

  return (
    <Card className={classes.card} variant="outlined">
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="body1">
            <b>ID:</b>
          </Typography>
          <ClipboardListField source="id" createRows={copyAccountRows} record={account} />
          <Typography variant="body1">
            <b>Name:</b>
            {' '}
            {account.display_name}
          </Typography>
          <Typography variant="body1">
            <b>Processor ID:</b>
          </Typography>
          <ClipboardListField source="processor_account_id" createRows={copyProcessorRows} record={account} />
          <Chip label={account.status} color="secondary" />
        </CardContent>

        {account.processor === 'stripe'
        && (
          <CardActions
            classes={{ spacing: classes.actionSpacer }}
          >
            <Button
              variant="contained"
              color="primary"
              label="Studio's Stripe Dashboard"
              onClick={visitAccount}
            >
              <LaunchIcon />
            </Button>

            <Button
              variant="outlined"
              color="primary"
              label="Stripe Account Details"
              onClick={visitAccountDetails}
            >
              <LaunchIcon />
            </Button>
          </CardActions>
        )}
      </div>
    </Card>
  );
};

export default MerchantAccountCard;
