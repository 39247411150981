import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import { map } from 'lodash';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Error,
  useDataProvider,
} from 'react-admin';
import SiteCard from './SiteCard';
import InstructorSubscriptionCard from './InstructorSubscriptionCard';
import InstructorVerificationLevelCard from './InstructorVerificationLevelCard';
import ClipboardListField from '../ClipboardListField';
import MerchantAccountCard from './MerchantAccountCard';
import NewsletterVerificationsCard from './NewsletterVerificationsCard';

const InstructorDetail = (props) => {
  const dataProvider = useDataProvider();
  // eslint-disable-next-line react/destructuring-assignment
  const [instructor, setInstructor] = useState(props.instructor);
  const [error, setError] = useState();

  const { sites, merchant_accounts: accounts, id } = instructor;
  const onRefresh = () => {
    dataProvider.getOne('instructors', { id })
      .then(({ data }) => {
        setInstructor(data);
      })
      .catch((e) => {
        setError(e);
      });
  };

  useEffect(() => {
    if (dataProvider && id) onRefresh();
  }, [dataProvider, id]);

  if (error) return <Error error={error} />;
  if (!instructor) return null;

  const copyInstructorRows = (value) => [
    value,
    `Instructor.find_by(id: "${value}")`,
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">
          <b>ID:</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ClipboardListField source="id" createRows={copyInstructorRows} record={instructor} />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            <b>Subscription:</b>
          </Typography>
          <InstructorSubscriptionCard instructor={instructor} onRefresh={onRefresh} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            <b>Verification Level:</b>
          </Typography>
          <InstructorVerificationLevelCard instructor={instructor} onRefresh={onRefresh} />
        </Grid>
      </Grid>

      <NewsletterVerificationsCard instructor={instructor} onRefresh={onRefresh} />

      <Grid item xs={12}>
        <Typography variant="body1">
          <b>Sites:</b>
        </Typography>
      </Grid>
      <Grid container spacing={1} style={{ margin: 8 }}>
        {sites.map((site) => (
          <Grid key={site.id} xs={12} sm={6} item>
            <SiteCard site={site} />
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1">
          <b>Merchant Accounts:</b>
        </Typography>
      </Grid>
      <Grid container spacing={1} style={{ margin: 8 }}>
        {map(accounts, (account) => (
          <Grid key={account.id} xs={12} sm={6} item>
            <MerchantAccountCard account={account} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default InstructorDetail;
