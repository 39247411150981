import {
  Avatar,
  Chip,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  get,
  isEmpty,
} from 'lodash';
import { DateField } from 'react-admin';
import FaceIcon from '@material-ui/icons/Face';
import WarningIcon from '@material-ui/icons/Warning';
import * as React from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/styles';
import SignInAsUser from './SignInAsUser';
import ClipboardListField from '../ClipboardListField';

const useStyles = makeStyles((theme) => createStyles({
  paper: {
    width: 320,
    margin: `${theme.spacing(1)}px`,
    padding: theme.spacing(2),
  },
  largeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const UserInfo = ({ user }) => {
  const classes = useStyles();
  const copyUserRows = (value) => [
    value,
    `User.find_by(id: "${value}")`,
  ];

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item>
          <Avatar
            alt={user.first_name}
            className={classes.largeAvatar}
            src={get(user, 'profile_image.profile_image.square_medium.url', '')}
          />
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {user.first_name}
            {' '}
            {user.last_name}
          </Typography>
          <Typography variant="body1">{user.email}</Typography>
          {!user.confirmed && (
            <Grid item>
              <Chip
                icon={<WarningIcon />}
                label="Not Confirmed"
                color="primary"
                variant="outlined"
              />
            </Grid>
          )}
          <Grid item>
            <Typography variant="body2">
              Created on: &nbsp;
              <DateField
                record={user}
                source="created_at"
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              ID:
            </Typography>
            <ClipboardListField source="id" createRows={copyUserRows} record={user} />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: 16 }}>
          {!isEmpty(get(user, 'instructors'))
            && (
              <Grid item>
                <Chip
                  icon={<FaceIcon />}
                  label="Instructor"
                  color="primary"
                  variant="outlined"
                />
              </Grid>
            )}
          {!isEmpty(get(user, 'client'))
            && (
              <Grid item>
                <Chip
                  icon={<FaceIcon />}
                  label="Client"
                  color="primary"
                  variant="outlined"
                />
              </Grid>
            )}
          <Grid item>
            <SignInAsUser user={user} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserInfo;
