import {
  Card,
  Grid,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import { useCallback } from 'react';
import { get } from 'lodash';
import {
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useNotify,
} from 'react-admin';
import api from '../../api';

const VerificationUpdateToolbar = (props) => (
  <Toolbar {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

export const amplitudeBaseUrl = `https://analytics.amplitude.com/offeringtree/project/${process.env.REACT_APP_AMPLITUDE_PROJECT_ID || '304671'}`;

const InstructorVerificationLevelCard = ({ instructor, onRefresh }) => {
  const notify = useNotify();
  const save = useCallback((record) => {
    api.put(`instructors/${instructor.id}`, {
      instructor: {
        verification_level: record.verification_level,
      },
    })
      .then(() => {
        onRefresh();
        notify('Updated Verification Level');
      })
      .catch((e) => {
        notify(`Error updating Verification Level: ${e}`, 'error');
      });
  });

  return (
    <Card variant="outlined" style={get(instructor, 'verification_level') !== 3 ? { border: '3px solid orange' } : {}}>
      <Grid item xs={12} style={{ padding: 16 }}>
        <Typography variant="body2">
          <b>Offerings Count:</b>
          &nbsp;
          {get(instructor, 'verification_level_data.offering_count')}
        </Typography>
        <Typography variant="body2">
          <b>Contacts Count:</b>
          &nbsp;
          {get(instructor, 'verification_level_data.contact_count')}
        </Typography>
        <Typography variant="body2">
          <a
            href={`${amplitudeBaseUrl}/search/user_id%3D${get(instructor, 'user.id')}`}
            target="_blank"
            rel="noreferrer"
          >
            View Amplitude Data
          </a>
        </Typography>

      </Grid>
      <SimpleForm record={instructor} toolbar={<VerificationUpdateToolbar />} save={save}>
        <SelectInput
          fullWidth
          source="verification_level"
          optionValue="level"
          choices={[
            { level: 0, name: 'Level 0 - Unverified' },
            { level: 1, name: 'Level 1 - Can upload contacts and send newsletters to < 250 contact' },
            { level: 2, name: 'Level 2 - Can send newsletters to > 250 contacts with a manual review' },
            { level: 3, name: 'Verified. Can send newsletters to large contact lists' },
          ]}
        />
      </SimpleForm>
    </Card>
  );
};

export default InstructorVerificationLevelCard;
