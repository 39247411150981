/* eslint-disable react/prop-types */
import {
  Button,
  Error,
  Loading,
} from 'react-admin';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import * as urlParse from 'url-parse';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import api from '../../api';

const SignInAsUser = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const redirectUrl = user.landing_url;
  const signInAsUser = () => {
    api.post('users/authorization_code',
      {
        user_id: user.id,
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID || 'a3c7571862d6adeef647decef567a054da5e5faad9498aa008917014ebd45942',
        redirect_uri: redirectUrl,
      })
      .then(({ data }) => {
        setLoading(false);
        const url = urlParse(redirectUrl, true);
        window.open(
          `${url.protocol}//${url.host}/auth/with_authorization_code?code=${data.code}&redirect=${url.pathname}`,
          '_blank',
        );
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (isEmpty(user)) return null;

  return (
    <Button
      variant="contained"
      color="secondary"
      label={`Sign in as ${user.first_name}`}
      onClick={signInAsUser}
    >
      <VerifiedUserIcon />
    </Button>
  );
};

export default SignInAsUser;
