import {
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import { useCallback } from 'react';
import {
  get,
  isEmpty,
} from 'lodash';
import {
  Button,
  useNotify,
} from 'react-admin';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import api from '../../api';

const NewsletterList = ({ data, verifyNewsletter }) => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>Subject</TableCell>
          <TableCell>Send At</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell>{row.subject}</TableCell>
              <TableCell>{row.send_date_time}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  label="Mark As Verified"
                  onClick={() => verifyNewsletter(row.id)}
                >
                  <VerifiedUserIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  </TableContainer>
);

const NewsletterVerificationsCard = ({ instructor, onRefresh }) => {
  const newsletters = get(instructor, 'verification_level_data.flagged_newsletters');
  if (isEmpty(newsletters)) {
    return (<></>);
  }

  const notify = useNotify();
  const verifyNewsletter = useCallback((id) => {
    api.post(`newsletters/${id}/mark_verified`)
      .then(() => {
        onRefresh();
        notify('Marked Newsletter as Verified');
      })
      .catch((e) => {
        notify(`Error verifying newsletter: ${e}`, 'error');
      });
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">
          <b>Newsletters That Require Verification:</b>
        </Typography>
        <Card variant="outlined">
          <NewsletterList data={newsletters} verifyNewsletter={verifyNewsletter} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default NewsletterVerificationsCard;
