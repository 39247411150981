import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';
import { useNotify } from 'react-admin';

import {
  includes,
  isEmpty,
} from 'lodash';
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
} from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { useLocation } from 'react-router-dom';
import api from '../api';

const useStyles = makeStyles((theme) => createStyles({
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  actionSpacer: {
    padding: theme.spacing(1),
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DomainChecker = () => {
  const notify = useNotify();
  const query = useQuery();

  const [domain, setDomain] = useState(query.get('domain'));
  const [dnsInfo, setDnsInfo] = useState(null);

  const lookupDomain = () => {
    api.get(`custom_domains/lookup/?domain=${domain}`)
      .then((data) => {
        setDnsInfo(data.data.data);
      })
      .catch(() => {
        notify('Error looking up domain', 'error');
      });
  };

  useEffect(() => {
    if (!isEmpty(query.get('domain'))) {
      lookupDomain();
    }
  }, []);

  return (
    <Grid
      style={{ padding: 16 }}
      container
      spacing={4}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="domain"
          label="Domain"
          variant="outlined"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <Button variant="contained" onClick={lookupDomain}>
          Lookup
        </Button>
      </Grid>

      {dnsInfo && <DomainDetails dnsInfo={dnsInfo} />}
    </Grid>
  );
};

const DomainDetails = ({ dnsInfo: { cname, whois: { nameservers, registrar } } }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <div className={classes.details}>
        <CardContent className={classes.content}>
          {!isEmpty(cname) && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <b>CNAME:</b>
                  {` ${cname}`}

                  {includes((process.env.REACT_APP_VALID_CNAMES || 'customdomains.offeringtree-staging.com').split(','), cname) ? (
                    <CheckIcon color="primary" />
                  ) : (
                    <CancelIcon color="error" />
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}
          {!isEmpty(nameservers) && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Nameservers:</b>
                  {` ${nameservers}`}
                </Typography>
              </Grid>
            </Grid>
          )}
          {!isEmpty(registrar) && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Registrar:</b>
                  {` ${registrar}`}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </div>
    </Card>
  );
};

export default DomainChecker;
