import { createMuiTheme } from '@material-ui/core/styles';
import {
  lightGreen,
  orange,
  red,
} from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: { main: orange[500] },
    secondary: { main: lightGreen[800] },
    error: { main: red[500] },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    type: 'light',
  },
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
  overrides: {},
});

export default theme;
