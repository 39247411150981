import * as React from 'react';
import { useCallback } from 'react';
import {
  BooleanInput,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
} from 'react-admin';
import { trim } from 'lodash';
import { useHistory } from 'react-router-dom';
import api from '../api';

const SiteEditToolbar = (props) => (
  <Toolbar {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const SiteEdit = (props) => {
  const notify = useNotify();
  const history = useHistory();

  const save = useCallback((record) => {
    api.put(`sites/${record.id}`, {
      site: {
        domain_name: trim(record.domain_name),
        custom_domain: trim(record.custom_domain),
        provisioning_domain: record.provisioning_domain,
      },
    })
      .then(() => {
        notify('Updated Site');
        history.goBack();
      })
      .catch((e) => {
        notify(`Error updating Site: ${e}`, 'error');
      });
  });

  return (
    <Edit title="Edit Site" {...props}>
      <SimpleForm toolbar={<SiteEditToolbar />} save={save}>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="domain_name" />
        <TextInput source="custom_domain" />
        <BooleanInput label="Provisioning custom domain" source="provisioning_domain" />
      </SimpleForm>
    </Edit>
  );
};

export default SiteEdit;
