import * as React from 'react';
import {
  Datagrid,
  EmailField,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn style={{ width: 400 }} resettable />
  </Filter>
);

const UserList = (props) => (
  <List filters={<UserFilter />} {...props} exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" sortable={false} />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <EmailField source="email" />
    </Datagrid>
  </List>
);

export default UserList;
