import * as React from 'react';

import {
  Show,
  Tab,
  TabbedShowLayout,
} from 'react-admin';
import {
  get,
  isEmpty,
} from 'lodash';
import UserInfo from './UserInfo';
import InstructorDetail from './InstructorDetail';
import UserLogs from './UserLogs';

const UserDetailPage = (props) => {
  const Aside = ({ record }) => (
    <div style={{ width: 336, marginRight: 8, marginTop: -8 }}>
      {record
        && (
          <>
            <UserInfo user={record} />
            <UserLogs user={record} />
          </>
        )}
    </div>
  );

  const PageTitle = ({ record }) => (
    <span>{record ? `${record.first_name} ${record.last_name}` : ''}</span>
  );

  const TabLayout = ({ record }) => {
    const isInstructor = !isEmpty(get(record, 'instructors'));
    const isClient = !isEmpty(get(record, 'client'));
    const isDirectoryMember = !isEmpty(get(record, 'directory_member'));

    return (
      <TabbedShowLayout style={{ paddingBottom: 16 }}>
        {isInstructor && (
          <Tab label="instructor">
            <InstructorDetail instructor={record.instructors[0]} />
          </Tab>
        )}
        {isClient && (
          <Tab label="client">
            <p>TODO</p>
          </Tab>
        )}
        {isDirectoryMember && (
          <Tab label="directory member">
            <p>TODO</p>
          </Tab>
        )}
      </TabbedShowLayout>
    );
  };
  return (
    <Show {...props} aside={<Aside />} title={<PageTitle />}>
      <TabLayout />
    </Show>
  );
};

export default UserDetailPage;
