import * as React from 'react';
import {
  Admin,
  Resource,
} from 'react-admin';
import jsonapiClient from 'ra-jsonapi-client';
import DomainIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/People';

import {
  apiServer,
  authProvider,
} from './authProvider';
import UserList from './components/UserList';
import UserDetailPage from './components/UserDetail/UserDetailPage';
import theme from './theme';
import DomainChecker from './components/DomainChecker';
import SiteEdit from './components/SiteEdit';
import InstructorEdit from './components/InstructorEdit';

const dataProvider = jsonapiClient(apiServer, { total: 'count', updateMethod: 'PUT' });

const App = () => (
  <Admin theme={theme} authProvider={authProvider} dataProvider={dataProvider}>
    <Resource name="users" list={UserList} show={UserDetailPage} icon={UserIcon} />
    <Resource name="domains" list={DomainChecker} icon={DomainIcon} />
    <Resource name="sites" edit={SiteEdit} />
    <Resource name="instructors" edit={InstructorEdit} />
  </Admin>
);

export default App;
