import { get } from 'lodash';

const AUTH_STORAGE_KEY = 'auth';

export const apiServer = process.env.REACT_APP_API_SERVER || 'http://api.core-api.test';
export const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${apiServer}/users/login`, {
      method: 'POST',
      body: JSON.stringify({
        user: {
          email: username,
          password,
        },
      }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        if (get(auth, 'user.status') !== 'admin') {
          throw new Error('Not Authorized');
        }
        localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(auth));
        localStorage.setItem('token', auth.token); // jsonapiClient looks at this
      })
      .catch(() => {
        throw new Error('Network error');
      });
  },
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem(AUTH_STORAGE_KEY);
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem(AUTH_STORAGE_KEY)
    ? Promise.resolve()
    : Promise.reject()),
  logout: () => {
    localStorage.removeItem(AUTH_STORAGE_KEY);
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve('admin'),
  getIdentity: () => {
    try {
      const { user: { id, name } } = JSON.parse(localStorage.getItem('auth'));
      return Promise.resolve({ id, fullName: name });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
